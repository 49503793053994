
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: null },
    introduction: { type: String, required: true },
    image: { type: Object, required: true },
    primaryButtonLabel: { type: String, required: false, default: undefined },
    primaryButtonUrl: { type: String, required: false, default: undefined },
    secondaryButtonLabel: { type: String, required: false, default: undefined },
    secondaryButtonUrl: { type: String, required: false, default: undefined },
    backgroundColor: { type: String, required: false, default: 'bg-app-lime' },
  },
})
