
import uniq from 'lodash/uniq'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer, stripTags, truncate } from '~/assets/util'
import { isNewInShelter, isPlaced, isReactionStop, isReserved } from '~/assets/animal'

export default defineComponent({
  props: {
    slide: { type: Object, required: true },
    translationGroup: { type: String, required: false, default: 'default' },
  },
  setup(props) {
    const genderIconMap = { male: 'app-male', female: 'app-female', fallback: 'app-unknown-gender' }
    return {
      ...setupTranslationPrefixer('CarouselSlideAnimal', props.translationGroup),
      animalCity: computed(() => {
        const city = props.slide?.animalMap?.parts?.city || props.slide?.animalAddress?.city
        return uniq(city?.split(',').map((value) => value.trim()) || []).join(', ')
      }),
      genderIconMap,
      isAvailable: computed(() => props.slide?.animalType === 'available'),
      isFound: computed(() => props.slide?.animalType === 'found'),
      isNewInShelter,
      isPlaced,
      isReactionStop,
      isReserved,
      stripTags,
      truncate,
    }
  },
})
