import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=2238c8e0"
import script from "./Carousel.vue?vue&type=script&lang=js"
export * from "./Carousel.vue?vue&type=script&lang=js"
import style0 from "./Carousel.vue?vue&type=style&index=0&id=2238c8e0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,AtomIconArrowLeft: require('/home/userapp/components/Atom/Icon/IconArrowLeft.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default})
