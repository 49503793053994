
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { Glide, GlideSlide } from 'vue-glide-js'
import { setupTranslationPrefixer } from '~/assets/util'

//
// For vue-glide documentation, see https://antonreshetov.github.io/vue-glide/props/
//

export default defineComponent({
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {
    slides: { type: Array, required: true, default: () => [] },
    type: { type: String, required: false, default: 'carousel', validator: (value) => ['slider', 'carousel'].includes(value) },
    breakpoints: {
      type: Object,
      required: false,
      default: () => {
        return {
          639: { perView: 1 },
          1023: { perView: 2 },
          1749: { perView: 3 },
          999999: { perView: 4 },
        }
      },
    },
  },
  setup() {
    const activeSlide = ref(0)
    const currentPerView = ref(3)
    const slider = ref()
    return { activeSlide, currentPerView, slider, ...setupTranslationPrefixer('carousel') }
  },
  mounted() {
    this.currentPerView = this.$refs.slider?.glide.settings.perView || 3
  },
  methods: {
    onResize() {
      this.currentPerView = this.$refs.slider?.glide.settings.perView || 3
    },
  },
})
