
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'
import { setupSiteConfig } from '~/assets/craft'

export default defineComponent({
  props: {
    translationGroup: { type: String, required: false, default: 'default' },
    animalCounts: { type: Object, required: true },
    categories: { type: Array, required: true },
  },
  setup(props) {
    const { site } = setupSiteConfig()
    const totalCount = Object.values(props.animalCounts).reduce((a, b) => a + b, 0)
    const amendedCategories = props.categories.map((category) => ({
      ...category,
      count: props.animalCounts[category.slug] || 0,
    }))
    const getUrl = (category) => {
      switch (site) {
        case 'default':
        case 'ikZoekBaas':
        case 'mijnDierIsZoek':
          return category.url
        default:
          // We are on a subsite, therefore we will include the subsite in the url as a pre-filter
          return `${category.url}?site=${encodeURIComponent(site)}`
      }
    }
    return {
      ...setupTranslationPrefixer('AnimalCategories', props.translationGroup),
      amendedCategories,
      getUrl,
      totalCount,
    }
  },
})
