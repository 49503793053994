import { getDateDifference } from '~/assets/util'

/**
 * if the animal is new in shelter.
 * @param {string} date
 * @param {string} availability
 * @returns {boolean}
 */
export function isNewInShelter(date, availability) {
  return !isNaN(Date.parse(date)) && availability === 'available' ? getDateDifference(date).days < 7 : false
}

/**
 * if the animal has availability "reserved".
 * @param {string} availability
 * @returns {boolean}
 */
export function isReserved(availability) {
  return availability === 'reserved'
}

/**
 * if the animal has availability "placed".
 * @param {string} availability
 * @returns {boolean}
 */
export function isPlaced(availability) {
  return availability === 'placed'
}

/**
 * if the animal has availability "reactionStop".
 * @param {string} availability
 * @returns {boolean}
 */
export function isReactionStop(availability) {
  return availability === 'reactionStop'
}

/**
 * if the animal has availability "availabilitySoon".
 * @param {string} availabilitySoon
 * @returns {boolean}
 */
export function isAvailableSoon(availabilitySoon) {
  return availabilitySoon === 'available-soon'
}

/**
 * Rewrite the animal detail url from the corporate site into the ikZoekBaas url
 *
 * from `http://www.dierenbescherming.nl/dierenasiel/dierentehuis-kennemerland/animal-redirect/available/honden/499053-jack`
 * into `http://ikzoekbaas.dierenbescherming.nl/asieldier/honden/499053-jack`
 *
 * Keep this in sync with symfony/src/GraphQL/SearchRepository::rewriteRedirectUrl
 */
export const rewriteAnimalDetailUrl = (url) => {
  if (!url) {
    return url
  }

  const match = url.match(
    /^(?<protocol>https?):\/\/(((?<prefix>[tap])|www)[.])?(?<host>.+)\/(?<departmentTypeSlug>dierenambulance|dierenasiel|extern|hondenschool|wildopvang)\/(?<siteSlug>.+)\/animal-redirect\/(?<animalType>.+)\/(?<category>.+)\/(?<idAndSlug>.+)$/
  )
  if (!match) {
    return url
  }

  const { protocol, host, prefix, animalType, category, idAndSlug } = match.groups

  switch (animalType) {
    case 'available':
      return `${protocol}://${prefix ? `${prefix}-` : ''}ikzoekbaas.${host}/asieldier/${category}/${idAndSlug}`
    case 'found':
      return `${protocol}://${prefix ? `${prefix}-` : ''}mijndieriszoek.${host}/gevonden-dieren/${category}/${idAndSlug}`
    case 'missing':
      return `${protocol}://${prefix ? `${prefix}-` : ''}mijndieriszoek.${host}/vermiste-dieren/${category}/${idAndSlug}`
    default:
      return url
  }
}
