import { render, staticRenderFns } from "./MatrixBanner.vue?vue&type=template&id=7b54988f&scoped=true"
import script from "./MatrixBanner.vue?vue&type=script&lang=js"
export * from "./MatrixBanner.vue?vue&type=script&lang=js"
import style0 from "./MatrixBanner.vue?vue&type=style&index=0&id=7b54988f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b54988f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomButton: require('/home/userapp/components/Atom/Button.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default})
