
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'
import { setupSiteConfig } from '~/assets/craft'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  setup() {
    const { site } = setupSiteConfig()
    const store = useStore()
    return {
      ...setupTranslationPrefixer('VariantHomeDetail', site),
      animalCounts: computed(() => store.getters['variant-home-detail/animalCounts']),
      site,
    }
  },
})
