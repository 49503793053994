
import { defineComponent, ref, useFetch, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    animalId: { type: [Number, String], required: true },
  },
  setup(props, { emit }) {
    const store = useStore()
    const state = ref('loading')
    const data = ref(null)

    useFetch(async () => {
      try {
        const animal = await store.dispatch('animal/fetch', { id: props.animalId })
        const shelter = animal.site.handle === 'vermisteDieren' ? {} : await store.dispatch('shelter/fetch', { site: animal.site.handle })
        state.value = animal && shelter ? 'ready' : 'error'
        data.value = { animal, shelter }
      } catch (error) {
        emit('error', { animalId: props.animalId, error })
        state.value = 'error'
      }
    })

    return {
      data,
      state,
    }
  },
})
