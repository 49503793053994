import { render, staticRenderFns } from "./VariantHomeDetail.vue?vue&type=template&id=516b4daf&scoped=true"
import script from "./VariantHomeDetail.vue?vue&type=script&lang=js"
export * from "./VariantHomeDetail.vue?vue&type=script&lang=js"
import style0 from "./VariantHomeDetail.vue?vue&type=style&index=0&id=516b4daf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516b4daf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeHeaderAnimalHome: require('/home/userapp/components/Molecule/HeaderAnimalHome.vue').default,MoleculeAnimalCategories: require('/home/userapp/components/Molecule/AnimalCategories.vue').default,MoleculeCarouselSlideAnimal: require('/home/userapp/components/Molecule/Carousel/CarouselSlideAnimal.vue').default,OrganismLoaderFromAnimalId: require('/home/userapp/components/Organism/Loader/LoaderFromAnimalId.vue').default,MoleculeCarousel: require('/home/userapp/components/Molecule/Carousel/Carousel.vue').default,MoleculeMatrixBanner: require('/home/userapp/components/Molecule/Matrix/MatrixBanner.vue').default,MoleculeCardOverviewCover: require('/home/userapp/components/Molecule/CardOverviewCover.vue').default,MoleculeCollage: require('/home/userapp/components/Molecule/Collage.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,MoleculeMission: require('/home/userapp/components/Molecule/Mission.vue').default,TemplateBase: require('/home/userapp/components/Template/Base.vue').default})
