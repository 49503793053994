
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    background: { type: String, required: true },
    color: { type: String, required: true },
    inCarousel: { type: Boolean, required: false, default: false },
  },
})
