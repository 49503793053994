import { render, staticRenderFns } from "./CarouselSlideAnimal.vue?vue&type=template&id=163ff352&scoped=true"
import script from "./CarouselSlideAnimal.vue?vue&type=script&lang=js"
export * from "./CarouselSlideAnimal.vue?vue&type=script&lang=js"
import style0 from "./CarouselSlideAnimal.vue?vue&type=style&index=0&id=163ff352&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163ff352",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default,MoleculeBadgeInCorner: require('/home/userapp/components/Molecule/BadgeInCorner.vue').default,AtomIconBirthdayCake: require('/home/userapp/components/Atom/Icon/IconBirthdayCake.vue').default,AtomIconAppLocationPin: require('/home/userapp/components/Atom/IconApp/IconAppLocationPin.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default})
